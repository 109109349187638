.listContainer {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	gap: 20px;
	overflow-x: auto;
}

.cardContainer {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	margin: 20px;
	border: 1px solid #F07663;
	box-sizing: border-box;
	border-radius: 5px;
	padding: 20px;
	width: 400px;
	height: 400px;
}

.title {
	font-size: 24px;
}

.header {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	height: 50px;
	margin-bottom: 20px;
}

.master, .header {
	width: 100%;
}

.newItem {
	display: flex;
    justify-content: space-between;
	width: 100%;
	font-size: 16px;
}

.newItem input, .customInput {
	outline: none;
	background: transparent;
	border: 1px solid #F07663;
	border-radius: 4px;
	padding: 5px;
	margin-right: 10px;
	height: 50px;
	width: 300px;
	line-height: 20px;
	font-size: 18px;
}

.newItem button, .newMasterCreate button {
	height: 50px;
	background-color: #F07663;
	color: white;
	text-align: center;
	border: none;
	outline: none;
	width: 100px;
	border-radius: 5px;
	padding: 5px 10px;
	box-sizing: border-box;
}

.newItem button:disabled, .newMasterCreate button:disabled {
	cursor: not-allowed;
	opacity: 0.8;
}

.listItemContainer {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	width: 100%;
	height: 200px;
	overflow-y: auto;
	padding: 5px;
}

.listItem, .newMasterContainer {
	border: 1px solid #F07663;
	box-sizing: border-box;
	border-radius: 5px;
	padding: 8px;
}

.listItem {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	font-size: 18px;
	margin-bottom: 10px;
	color: #F07663;
	text-transform: capitalize;
	width: 100%;
}

.newMasterContainer {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	gap: 20px;
	width: max-content;
	margin: 20px auto;
	padding: 20px;
}

.customInput {
	border: none;
	border-radius: 0px;
	border-bottom: 1px solid #F07663;
}

.count {
	padding-top: 10px;
	border-top: 1px solid #F07663;
	margin-top: 20px;
}

.warningText {
	color: #F07663;
	margin: 20px 0 0 20px;
}