.flipbookContainer{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
}
.flipbook {
  margin: 10px auto;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  /* overflow: hidden; */
}
.grouping-dropdown {
  width: 20rem;
}
.flipbookPageNav {
  display: flex;
  position: relative;
  text-align: center;
  justify-content: center;
}
.flipbook-cart-button {
  position: absolute;
  right: 0;
  margin-right: 1rem;
}
.flpbk-action-btn {
  color: white;
  text-transform: uppercase;
  cursor: pointer;
  background-color: #F58974;
  padding: 0.5rem;
  border-radius: 0.25rem;
  transition: color 0.2s, background-color 0.2s;
  text-align: center;
  font-size: .9rem;
}

.flpbk-action-btn.flpbk-action-btn-inprogress {
  background-color: #aaaaaa;
  cursor: unset;
}

.brochureEditorContainer {
  height: 100%;
  padding: 1rem;
  overflow: auto;
  background-color: #f3f3f3;
}

.brochureEditorTitile {
  font-size: 0.8em;
  font-weight: bold;
  margin-bottom: 0.8rem;
  text-transform: uppercase;
  color: #767676;
}

.brochureEditorPageImageContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.brochureEditorPageSubtitle {
  font-size: .9rem;
  margin-bottom: .5rem;
  font-weight: bold;
}

.brochureEditorImageContainerSplit {
  width: 45%;
  text-align: center;
}

.fileUploadBtn:hover {
  border: 1px solid #F58974 !important;
}

.fileUploadBtnInput {
  outline: none;
  cursor: pointer;
}

.flipbookIcon {
  height: 100px;
  width: 100px;
}

.flipbookLink {
  font-size: 12px;
  cursor: pointer;
  color: #174ea6;
  margin: .5rem 1rem 0rem 1rem;
  text-align: right;
  font-size: 12px;
}
.flipbook-checkout-icon-container{
  width: 20%;
  align-items: center;
  display: flex;
  margin: 0.5rem 1.3rem;
}
.flipbook-checkout-icon{
  width: 16px;
  height: 16px;
}
.flipbook-type{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.flipbookCardTitle {
  font-weight: bolder;
  margin-bottom: 0.5rem;
  margin-left: 1rem;
  font-size: 18px;
  color: rgb(242, 117, 95);
  text-transform: capitalize;
}

.flipbookCardBtn {
  border: 1px solid #DEDEDE;
  border-radius: 0.25rem;
  padding-left: 1rem;
  padding-right: 1rem;
  margin-left: 1rem;
  margin-right: 1rem;
  background-color: white;
  color: #767676;
  font-size: 12px
}

.flipbookCardBtnIcon {
  margin: 0px 5px 2px 0px;
  font-size: 14px;
}

.flipbookCreateContainer {
  width: 25%;
  padding: 0.5rem;
  overflow: auto;
  border: 1px solid #DEDEDE;
  background-color: #F7F7F7;
  border-radius: 5px;
}

.flipbookCreateContenat {
  margin: 10%;
}

.flipbookTextFieldLabel {
  font-size: 0.9em;
  font-weight: bold;
  text-transform: uppercase;
  color: #767676;
}

.flipbookCreateTitle {
  margin: 5% 10%;
  text-align: left;
  font-size: 1.2rem;
  color: #404040;
}

.productBrochureEditorMainContainer {
  border: 1px solid #DEDEDE;
}

.flipbook-sample-temp {
  font-size: 12px;
  color: #555555;
  cursor: pointer;
}

textarea#copyToClipboard {
  opacity: 0;
  position: absolute;
  z-index: -9999;
  pointer-events: none;
}

.productBrochureEditorCategoryCard {
  background-color: #f9f9f9;
}

.flipbook-page-container {
  width: 400px;
  height: 640px;
  position: relative;
}

.flipbook-page-product-group-title {
  width: 100%;
  margin: 0px;
  padding: 0.5rem 1rem;
  font-weight: bold;
  background-size: cover;
  display: flex;
  align-items: center;
}

.flipbook-page-product-row {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin: 1rem auto;
  padding-left: 10px;
  padding-right: 10px;
}

.flipbook-page-product-card {
  cursor: pointer !important;
  display: flex !important;
  flex-direction: column-reverse !important;
  align-items: center !important;
  text-align: center !important;
  box-shadow: 0px 3px 3px #00000029 !important;
  border: 1px solid #70707070 !important;
  background-color: white !important;
  position: relative !important;
  margin: 8px !important;
}

.flipbook-page-product-card-title {
  position: absolute;
  top: 0.125rem;
  left: 0.125rem;
  font-size: 0.8em;
  font-weight: bold;
  color: #0053a8;
  margin-left: 8px;
  margin-top: 2px;
}

.flipbook-page-product-card-price {
  color: #0053a8;
  font-size: 0.75em;
  font-weight: bold;
  flex: 0 1 auto;
}

.flipbook-page-product-card-name {
  font-size: 0.75em;
  flex: 0 1 auto;
  line-height: 100%;
  color: #767676;
}

.flipbook-page-product-card-img {
  flex: 1 1 auto;
  align-self: stretch;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  margin-top: 0.4em;
}

.flipbook-page-product-card-img-details {
  display: flex;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  border: 1px solid #d8d8d8;
  border-radius: 5px;
}

.flipbook-productinfo-popup-content-value {
  font-size: 0.8em;
  line-height: 100%;
  color: #404040;
  padding: .2rem;
  display: flex;
  /*align-items: center;*/
  font-weight: bold;
}

.flipbook-productinfo-popup-content-label {
  font-size: 0.7rem;
  line-height: 100%;
  color: #767676;
  /*display: flex;*/
  /*align-items: center;*/
  margin-left: 1rem;
  padding: .2rem;
  font-weight: bold;
}

.flipbook-productinfo-popup-grid {
  display: grid;
  grid-template-columns: 5fr 10fr;
  grid-template-rows: 1fr;
  grid-column-gap: 10px;
  padding: .3rem;
}

.flipbook-popup-container {
  display: flex;
}

.flipbook-popup-img {
  width: 10rem;
  height: 10rem;
}

.flipbook-popup-content-list-container {
 margin: auto;
}
.flipbook-collection-image{
  width: 78px;
  height: 75px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  cursor: pointer;
  border-radius: 50%;
  box-shadow: 1px 3px 6px #00000029;
}

.flipbook-collection-image-title {
  font-family: Nunito;
  font-size: 12px;
  max-width: 50px;
  text-transform: capitalize;
  font-weight: 600;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap
}

.flipbook-collection-image-cover {
  width: 60px;
  height: 60px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  border-radius: 50%;
  box-shadow: 1px 3px 6px #00000029;
  cursor: pointer;
}
.flipbook-tiles-grid-container{
  display: grid !important;
  grid-template-columns: repeat(3 , 1fr);
  grid-auto-flow: row;
  grid-template-rows: repeat(1, 1fr);
  grid-column-gap: 20px;
  grid-row-gap: 10px;
}

@media screen and (max-width: 600px) {
  .flipbook-tiles-grid-container{
    grid-template-columns: repeat(2 , 1fr);
  }

  .flipbook-tiles-popover-container{
    margin: 1rem !important;
    width: 260px !important;
    max-height: 300px !important;
  }
  .flipbook-collection-image-cover{
    width: 34px;
    height: 34px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    cursor: pointer;
    border-radius: 50%;
    box-shadow: 1px 3px 6px #00000029;
  }
  .flipbook-page-product-group-title {
    padding: 0.2rem .2rem;
    font-size: .4rem;
  }
  .grouping-dropdown {
    width: 16rem;
  }
  .flipbook-page-product-row {
    margin: .5rem auto;
    padding-left: 5px;
    padding-right: 5px;
  }

  .flipbook-page-product-card {
    margin: .2rem !important;
  }

  .flipbook-page-product-card-title {
    font-size: 0.3em;
    margin-left: .2rem;
    margin-top: 0rem;
    top: 0rem;
    left: 0rem;
  }

  .flipbook-page-product-card-price {
    font-size: 7px !important;
  }

  .flipbook-page-product-card-name {
    font-size: 7px;
    flex: 0 1 auto;
  }

  .flipbook-page-product-card-img {
    margin-top: 0.5rem;
  }

  .flipbook {
    /*margin-top: 0px;*/
    margin: auto;
  }

  .flipbookContainer{
    /*background-color: #E7E7E7;*/
  }

  .flipbook-popup-img {
    width: auto;
  }

  .flipbook-popup-container {
    display: contents;
  }

  .flipbook-popup-content-list-container {
    margin-top: 1rem;
  }

  .flipbookCreateContainer {
    width: 80%;
  }

  .cart {
    margin: 1rem !important;
  }

  .flipbook-container-sidepanel-view {
    display: grid;
    grid-template-columns: 1fr !important;
    grid-template-rows: 92% 5% !important;
    height: 100% !important;
    grid-column-gap: 20px;
    grid-row-gap: 10px;
  }
  .flipbook-nav-btn-container {
    padding: .2rem !important;
  }
  .flipbook-nav-btn {
    font-size: 12px;
  }
  .flipbook-collection-image-title {
    font-size: 12px;
  }
  .flipbook-collection-image-cover{
    width: 45px;
    height: 45px;
  }
}

.flipbook-bar-container {
  background-color: #F9F9F9;
}

.flipbook-bar {
  margin: .2rem;
  display: flex;
  min-height: 3.5rem;
  justify-content: center;
}

.outline-none {
  outline-style: none !important;
}

.flipbook-bar-icon {
  margin: 0rem 0.2rem;
}

.flipbook-settings-popover-container {
  margin: 1rem;
  max-width: 365px;
}
.flipbook-tiles-popover-container {
  margin: 1rem;
  width: 400px;
  max-height: 380px;
}

.flipbook-text-label {
  color: #BBBBBB;
  font-size: 12px;
}

.flipbook-popover-text-header {
  color: #404040;
  font-size: 14px;
  margin: 1.5rem 0rem;
  font-weight: bold;
}

.flipbook-popover-text-sub-header {
  color: #767676;
  font-size: 12px;
  margin: .8rem 0rem;
}

.uppercase {
  text-transform: uppercase;
}

.flex-align-center {
  display: flex;
  align-items: center;
}

.settings-switch-lable {
  margin: 0rem;
  font-size: 14px;
  color: #999999;
}

.settings-switch-lable.active {
  color: #000000;
}

.settings-switch-button {
  margin: 0rem .5rem;
}

.radio-button-label {
  color: #767676 !important;
  margin-bottom: 0px !important;
}

.highlight-text {
  font-weight: bold;
  color: #000000;
}

.flipbook-popover-text-paragraph {
  color: #767676;
  font-size: 12px;
  margin: 0px;
}

.hyperlink-primary {
  color: #f2755f;
  text-decoration: underline;
}

.MuiFormControlLabel-label {
  font-size: 14px !important;
}

.MuiFormControlLabel-label.active {
  font-size: 14px !important;
}

.bg-img-upload-container {
  display: flex;
  margin: 1rem 0rem;
}

.bg-img-upload-section {
  border: 1px solid #DDDDDD;
  width: 60px;
  height: 80px;
  box-shadow: 5px 2px 10px #00000029;
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: 60px 80px;
}

.help-text {
  font-weight: normal;
  color: #afafaf;
  font-size: 11px;
}

#flipbook.shadow{
  -webkit-box-shadow: 0 4px 10px #666;
  -moz-box-shadow: 0 4px 10px #666;
  -ms-box-shadow: 0 4px 10px #666;
  -o-box-shadow: 0 4px 10px #666;
  box-shadow: 0 4px 10px #666;
}


.flipbook-text {
  color: #707070;
  font-size: 12px;
  margin: .4rem;
}

.primary-color {
  color: #f2755f;
}

.primary-color-border {
  border: 1px solid #f2755f;
}

.store-location-map-container {
  width: 100%;
  height: 50%;
}

.flipbook-create-section {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
}

.flipbook-container-sidepanel-view {
  display: grid;
  grid-template-columns: 8fr 2fr;
  grid-template-rows: 1fr;
  grid-column-gap: 20px;
  /*grid-row-gap: 10px;*/
}

.flipbook-nav-btn-container {
  display: flex;
  padding: .4rem;
}

.flipbook-nav-btn {
  display: flex;
  align-items: center;
  text-transform: capitalize;
  font-size: 14px;
  background-color: #ececec;
  border-radius: .5rem;
  cursor: pointer;
}

.flipbook { grid-area: 1 / 1 / 2 / 2; }

.cart {
  grid-area: 1 / 2 / 2 / 3;
  margin: 10px 0px;
}

.flipbook-container {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  grid-column-gap: 20px;
  grid-row-gap: 10px;
}

.flipbook { grid-area: 1 / 1 / 2 / 2; }

.flipbook-listing-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justif-content: center;
  align-items: center;
}

.flipbook-listing-container-new {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justif-content: center;
  align-items: center;
  background: white;
}

.flipbook-listing {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: center;
  padding: 2rem 0rem
}

.flipbook-create-btn {
  border: 1px solid #F6A59A;
  border-radius: 100vw;
  padding-left: 3rem;
  padding-right: 3rem;
  background-color: white;
  color: #F6A59A
}

.flipbook-items {
  display: flex;
  flex-direction: row;
  width: 80%;
  flex-wrap: wrap;
  justify-content: center
}

.user-store-selection-container {
  position: absolute;
  text-align: center;
  min-width: 25%;
  margin-top: 0.7rem;
  right: 0;
}
.user-store-selection-container-mobile {
  position: absolute;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: space-between;
  width: 5.5rem;
  display: flex;
  border-bottom: 1px solid #F2755F;
  margin: 0.7rem 0.1rem;
}
.user-store-selection-text{
  text-align: center;
  width: 4rem;
  color: #656565;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-weight: 900;
}
.store-selector-drawer-mobile{
  display: grid;
  grid-template-columns: 100%;
  height: 100%;
  grid-template-rows: 22% 65% 13%;
}
.store-locator-search-field{
  display: flex;
  justify-content: center;
  background: #333D4C;
  align-items: center;
}

.store-locator-search-field .MuiOutlinedInput-notchedOutline {
  border-color: rgb(255 255 255 / 100%);
}
.store-locator-search-field .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline{
  border-color: rgb(255 255 255 / 100%);
}
.store-locator-search-field #outlined-basic-store-label{
  color: white;
  font-weight: 600;
}
.user-store-selection-dropdown {
  background-color: white;
  margin: 0rem 1rem;
  text-align: center;
}


.change-number-alert {
  margin: 1rem;
  font-size: 12px;
}

.makeStyles-paper-12 {
  border: none !important;
}

.flipbook-main-container {
  width: 100%;
  height: auto;
  position: absolute;
}

.flipbook-loader {
  color: #F2755F;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  position: fixed;
  width: 100%
}

.flipbook-loader-tile {
  color: #F2755F;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%
}
.bulk-upload-loader {
  position: static;
  align-items: center;
}

.height-150-p {
  height: 150px;
}

.flipbook-edit-btn-block {
  pointer-events: none;
  cursor: not-allowed !important;
  background: #f0f0f0;
}

.flipbook-create-btn-block {
  pointer-events: none;
  cursor: not-allowed !important;
  background: #f0f0f0;
  color: #707070;
  border-color: #707070;
}

.flipbook-link-block {
  pointer-events: none;
  cursor: not-allowed !important;
  color: #707070;
}
.h-60{
  height: 60% !important;
}
.flipbook-csv-upload {
  display: flex;
  justify-content: center;
}

.flipbook-csv-template-container {
  display: flex;
  justify-content: space-between;
}
.store-selected-active{
  border: 1px solid #F2755F;
}
.store-selected-inactive {
  border-bottom: 1px solid #cccccc;
}
.flipbook-csv-error-text {
  color: #c91919;
}
.store-selector-mobile-container{
  padding: 1rem;
  display: grid;

  grid-template-columns: 10% 90%;
  grid-template-rows: 100%;
}
.store-selector-mobile-icon{
  display: flex;
  justify-content: center;
  align-items: flex-start;
  margin: 5px;
}
.store-selector-mobile-details{
  display: flex;
  flex-direction: column;
}
.store-selector-mobile-header{
  text-transform: capitalize;
  font-weight: 700;
}
.store-selector-mobile-content{
  text-overflow: ellipsis;
  overflow: hidden;
  width: 95%;
  height: 1.2em;
  white-space: nowrap;
}
.store-selector-cta{
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: -0.1px -0.1px 1px 0px black;
}
@media screen and (max-width: 600px) {
  .flipbook-main-container{
    height: 100%;
  }

  .flipbook-container {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 92% 5%;
    height: 100%;
    grid-column-gap: 20px;
    grid-row-gap: 10px;
  }
}