.product-specification-container {

}

.product-card-variant-chip-selection {
    display: flex;
    flex-direction: row;
}

.collapse-fade-out {
    -webkit-mask-image: -webkit-gradient(linear, left top,
    left bottom, from(rgba(0, 0, 0, 1)), to(rgba(0, 0, 0, 0)));
}

.popup-header-section-title {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 1rem 4.5rem;
    font-size: 16px;
    font-weight: 600;
}

.product-card-show-more {
    font-size: 12px;
    color: #F2755F;
    font-weight: 700;
    cursor: pointer;
}

.product-card-variant-listing {
    margin: 0.5rem 0rem;
    table-layout: fixed;
    width: 100%;
}
.header-stock-status-container {
    justify-content: center;
    align-items: center;
    display: none;
}

.header-stock-status {
    color: red;
    border: 1px solid red;
    padding: .2rem .4rem;
    border-radius: .5rem;
    font-size: 11px;
    width: auto;
    height: auto;
    margin: 0rem;
}

@media screen and (max-width: 600px) {
    .product-specification-container {
        width: 100%;
    }

    .popup-header-section-title-wrap {
        width: 170px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .popup-header-section-title {
        padding: 0rem 1rem;
    }

    .product-card-popup-key {
        width: 45%;
    }

    .product-card-variant-listing {
        margin: 0.5rem 0rem;
        table-layout: fixed;
        width: 100%;
    }

    .product-card-popup-colon {
        width: 5%;
    }

    .stock-status-background {
        background: #f9f9f9;
        height: 33px;
        align-items: center;
        justify-content: center;
        width: 100%;
        display: flex;
    }

    .product-specification {
        height: 100%;
        overflow-y: auto;
        overflow-x: hidden;
        padding-top: 2rem;
    }
    .header-stock-status-container {
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

.product-badge {
    position: relative;
    display: inline-block;
    overflow: hidden;
}
.product-badge img {
    display:block;
}
.product-badge.promoted.out-of-stock:after {
    content: attr(data-awards);
    position: absolute;
    background: #C55;
    color: white;
    width: 100%;
    line-height: 1em;
    text-align: center;
    padding: 0.5em 0;
    box-sizing: border-box;
    top: 0;
    right: 0;
    transform: rotate(-45deg) translate(-35%,-70%);
    transform-origin: 40% 50%;
    font-size: 12px;
}

