.container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end !important;
    grid-area: order-summary;
}

.orderSummaryTable {
    border-collapse: collapse !important;
    margin-bottom: 20px;
    width: max-content;
    page-break-before: always;
}

.orderSummaryTable tr {
    background-color: white;
    width: max-content;
    border: 1px solid #F3F3F3 !important;
}

.orderSummaryTable td {
    padding: 15px 10px;
    min-width: 50px;
    width: fit-content;
    word-wrap: break-word;
    border: 0px !important;
}

.summary {
    width: 300px;
    /* margin-left: 75%; */
}

.summary .orderSummaryTable,
.summary tr {
    width: max-content;
    border: 0px !important;
}

.summaryRow td {
    padding: 5px !important;
    text-align: right !important;
}

.summary td:last-child {
    padding: 10px !important;
}

.summaryField+td {
    font-weight: bold;
    text-transform: capitalize;
}