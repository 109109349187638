.header {
    display: flex;
    justify-content: space-around;
    align-items: center;
}
.offerMasterContainer {
    padding: 25px;
    background: #F9F9F9;
}

.topBox {
    padding: 0 20px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.createOfferLink {
    color: #F07663;
    text-decoration: none;
}

.createOfferLink:hover {
    color: #F07663;
    text-decoration: none;
}

.breadcrumbText {
    font-size: 14px;
}

.breadcrumbLink {
    color: #999999;
    text-decoration: none;
}

.breadcrumbLink:hover {
    color: #999999;
    text-decoration: none;
}
