.container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    grid-area: payment-info;
}

.table,
.table tr,
.table td {
    border-collapse: collapse !important;
    border: 0px !important;
}

.table th,
.table td {
    padding: 5px 10px;
    min-width: 50px;
    width: max-content;
    max-width: 50%;
    word-wrap: break-word;
    font-weight: 700;
}