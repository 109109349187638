.statusIcon {
    margin-right: 5px;
}

.backdrop {
    position: absolute;
    opacity: 0.5;
    z-index: 0;
    color: #FFF;
}

.viewOfferLink {
    color: #F07663;
    text-decoration: none;
}

.viewOfferLink:hover {
    color: #F07663;
    text-decoration: none;
}
