.flipbook-side-panel{
    grid-area: 1 / 2 / 2 / 3;
    border: 1px solid #DEDEDE;
    transform: none;
    transition: transform 225ms cubic-bezier(0, 0, 0.2, 1) 0ms;
    background-color: #F7F7F7;
    height: 100%;
}
.flipbook-side-panel-header{
    padding: 1rem 0rem;
    background-color: #F2755F;
}
.flipbook-side-panel-header-text {
    color: #FFFFFF;
    font-size: 14px;
    text-transform: uppercase;
    /*font-weight: bold;*/
    margin: 0rem 1rem;
}
.flipbook-side-panel-body{
    padding: 8px;
}
.flipbook-side-panel-tools{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}
.flipbook-side-panel-crop-container{
    margin-top: 15px;
}
.side-panel-variant-btn {
    margin: 1rem;
    cursor: pointer;
    padding: .4rem;
    border-radius: 1rem;
    font-size: 12px;
    width: 100%;
    text-align: center;
    color: #707070;
    font-weight: 600;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    background-color: #FFFFFF;
    opacity: 0.6;
}

.side-panel-variant-btn.active {
    /*border: 1px solid #F4907E;*/
    font-weight: 600;
    /*background-color: #F4907E;*/
    color: #707070;
    /*box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);*/
    box-shadow: -1px -1px 26px -11px rgba(0,0,0,0.98);
    -webkit-box-shadow: -1px -1px 26px -11px rgba(0,0,0,0.98);
    -moz-box-shadow: -1px -1px 26px -11px rgba(0,0,0,0.98);
    opacity: 1 !important;
}

.side-panel-flipbookIcon {
    height: 50px;
    width: 50px;
}