.label {
    display: flex;
    align-items: center;
    font-size: 14px;
}
.quantity-grid-layout {
    display: grid;
    grid-template-columns: auto auto auto;
}
.quantity-input {
    width: 100%;
    text-align: center;
    color: #f2755f;
    font-weight: bold;
    border-width: 0px !important;
}

.quantity-input-decrement {
    border-width: 0.25px 0px 0.25px 0.25px;
    border-style: solid;
    border-color: #cccccc;
    text-align: center;
    width: 30px;
    background: white;
    cursor: pointer;
    height: 30px;
}
.quantity-input-field {
    border: 0.25px solid #cccccc;
    text-align: center;
    width: 30px;
    background: white;
    height: 30px;
}

.quantity-input-increment {
    border-width: 0.25px 0.25px 0.25px 0px ;
    border-style: solid;
    border-color: #cccccc;
    cursor: pointer;
    background: white;
    text-align: center;
    width: 30px;
    height: 30px;
}

.cart-listing-item-qty {
    display: flex;
    /*border-radius: .5rem;
    width: 105px;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    border: 1px solid #d4d4d4;
    background-color: #FFFFFF;*/
}

.cart-item-qty { grid-area: 2 / 2 / 3 / 4; }

