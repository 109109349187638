.container {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	grid-area: tenant-info;
}

.fields {
	display: flex;
	flex-direction: column;
	gap: 6px;
	margin-top: 5px;
	font-size: 12px;
	word-wrap: break-word;
	max-width: 100ch;
}

.field {
	font-weight: 600;
}

.field>img {
	margin-bottom: 2rem;
}

.label {
	margin-right: 5px;
	font-weight: 600;
}

.label::after {
	content: ':';
}

.label+.field {
	font-weight: normal;
	font-size: 14px;
	align-self: center;
}