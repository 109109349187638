.flipbook-video-layout-container{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    position: absolute;
}
.upper-tile-video{
    padding: 2rem 1.5rem 0rem 1.5rem;
}
.lower-tile-video{
    padding: 0.5rem 1.5rem 2rem 1.5rem;
}
.single-tile-video{
    padding: 2rem 1.5rem;
}
.flipbook-video-layout-modal {
    display: flex;
    align-items: center;
    justify-content: center;
}
.flipbook-video-layout-modal-container{
    width: 42%;
    height: 85%;
    background: white;
}
.flipbook-video-layout-title{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #777777;
    font-size: 17px;
    text-transform: uppercase;
}
.flipbook-video-layout-header{
    width: 100%;
    height: 10%;
    box-shadow: 0 1px 1px 0 rgba(0,0,0,.16);
    position: relative;
    display: flex;
    align-items: center;
}
.flipbook-upload-video-preview-screen{
    display: flex;
    justify-content: center;
}
.flipbook-video-url-status-container{
    display: flex;
    align-items: flex-end;
    padding: 5px;
}
.video-upload-url-container{
    display: flex;
    flex-direction: row;
}
.video-upload-modal-content{
    height: 90%;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    margin: 2% 10%;
}
.video-upload-modal-form{
    display: flex;
    flex-direction: column;
}
.video-upload-settings-label {
    color: #030303;
    font-size: 12px;
}
.video-player-position-unset {
    position: unset !important;
}