.container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    grid-area: hsn-summary;
}

.finalRow {
    font-weight: bold;
}