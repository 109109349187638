@import '../../../../../styles/colors';

#campaignFilters {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  align-items: flex-end;
  flex-wrap: wrap;
  gap: 1rem 1.5rem;
  margin: 2rem 1rem;

  .searchFormControl {
    grid-column: span 2;
    flex: 1;
    fieldset {
      top: 0;
    }
    & > *:first-child {
      border-radius: 0.5rem;
    }
    svg {
      margin-right: 0.4rem;
    }
    input {
      padding-block: 0.95rem;
    }
  }

  .formControl {
    label {
      color: $normal-text !important;
    }
    &:focus-within {
      label {
        color: $primary-color !important;
      }
    }
  }
}
