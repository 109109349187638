.alignCenter {
    display: flex;
    align-items: center;
}

.paginationBar {
    display: flex;
    justify-content: space-between;
    padding: 15px;
    font-size: 14px;
}

.pageNumberBox {
    margin: 0 15px;
}

.rowsPerPageLabel {
    color: #A8A8A8;
    margin-right: 10px;
}

.rowsPerPageSelect {
    min-width: 70px;
}
