.sections {
	display: grid;
	grid-template-areas:
		"tenant-info . order-info"
		"divider divider divider"
		"customer-info . ."
		"order-list order-list order-list"
		"payment-info . order-summary"
		"hsn-summary hsn-summary hsn-summary"
		"misc-info misc-info misc-info"
		"footer footer footer";
	grid-template-columns: 1fr 1fr 1fr;
	padding: 30px;
	padding-bottom: 10px;
	font-size: 12px;
	font-family: Nunito;
	gap: 20px;
}

.footer {
	display: flex;
	grid-area: footer;
	border-top: 1px solid #DDDDDD;
	padding: 10px 20px;
	align-items: center;
	justify-content: space-between;
	color: #999999;
	width: 100%;
	background: white
}

.footer img {
	height: 20px;
	width: auto;
	margin-left: 10px;
}

.footer .downloadBtn {
	font-size: 10px;
	text-decoration: underline;
	color: #999999;
}

.footer .downloadBtn:hover {
	cursor: pointer;
}