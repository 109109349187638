.container {
	background-color: #F7F7F7;
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 8px 20px;
}

.container input {
	border: none;
	outline: none;
	background-color: transparent;
	color: #777;
	margin-left: 20px;
	text-align: start;
	line-height: 100%;
	font-size: 16px;
}
