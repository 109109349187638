.mainContainer {
    top : 0;
    left: 0;
    position : fixed;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.1); 

    display: flex;
    justify-content: center;
    align-items: center;
}

.innerContainer {
    padding : 30px;
    background-color : white;
    position : absolute;

    border-radius : 5px;

    width : 600px;

    display : flex;
    flex-direction: column;
    gap : 10px;

    justify-content: center;
    align-items: center;
}

.line{
    width : 100%;
    height : 1px;
    background-color: #CDD1DC;
}

.form{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 540px;
}

.nameField{
    display: flex;
    width: 100%;
    justify-content: space-around;
    margin-bottom: 40px;
}

.textField{
    width: 35%;
    text-align: left;
    padding-top: 0px;
    padding-bottom: 0px;
}

.bottomContainer{
    display: flex;
    width: 100%;
}