.search-product-container {
    width: 30rem;
    height: 50rem;
    margin: 1rem;
}

.search-box {
    margin: 1rem;
}

.flipbook-popover-text-header {
    color: #404040;
    font-size: 14px;
    margin: 1.5rem 0rem;
    font-weight: bold;
}

.product-listing-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 20px;
    grid-row-gap: 20px;
}

.product-listing-container {
    padding: 1rem;
    margin: 0rem;
    background-color: #f2f2f2;
}

.result-line {
    display: flex;
    align-items: center;
    text-align: center;
    color: #777777;
    font-size: 12px;
    padding: 1rem 0rem;
}

.result-line::before,
.result-line::after {
    content: '';
    flex: 1;
    border-bottom: 1px solid #DDDDDD;
}

.result-line:not(:empty)::before {
    margin-right: .25em;
}

.result-line:not(:empty)::after {
    margin-left: .25em;
}

@media screen and (max-width: 600px) {
    .search-product-container {
        width: 18rem;
        height: 35rem;
        margin: 1rem;
    }

    .product-listing-grid {
        grid-template-columns: repeat(1, 1fr);
    }
    .product-listing-container {
        padding: 1rem 1rem;
    }
}

@media screen and (max-width: 325px) {
    .search-product-container {
        width: 16rem;
    }
}
