.selected{
    color : #B43021;
    border : 1px solid #B43021;
    background-color: #FEF3F2;
}

.deselected{
    border : 1px solid #9D9D9D;
    color : black;
    background-color: white;
}