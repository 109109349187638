.label-printing-error-accordion{
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
}
.label-printing-switch-container{
    display: flex;
    flex-direction: row;
}
.label-printing-error-prompt{
    margin: 0;
}