@import '../../../../styles/colors';

#transactionReport {
  a:hover {
    color: $primary-color;
  }

  .transactionReportTable {
    td {
      font-size: 1rem;
      font-weight: 500;
    }

    td:first-child,
    th:first-child {
      padding-left: 1rem;
    }
  }
}
